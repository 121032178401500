<template>
  <div class="p-3">
    <div class="d-flex flex-row align-items-start flex-wrap">
      <select
        class="form-control form-control-sm shadow-none col-md-1 col-sm-4 m-1"
        v-model="year"
        @change="change_filter"
      >
        <option value="all">все</option>
        <option v-for="(item, idx) in years()" :key="idx" :value="item">{{
          item
        }}</option>
      </select>
      <input
        type="text"
        class="form-control  form-control-sm change-user-input shadow-none  col-md-2 col-sm-4 m-1"
        placeholder="поиск по названию СЦ"
        v-model="search_text"
      />
      <input
        type="text"
        class="form-control  form-control-sm change-user-input shadow-none  col-md-2 col-sm-4 m-1"
        placeholder="ремонтов больше"
        v-model="count_repair"
        @input="change_filter"
      />
    </div>
    <div class="overflow-auto">
      <table class="table table-sm table-hover" v-if="list.length">
        <tr>
          <th></th>
          <th class="cursor" @click="set_sort('user')">сц</th>
          <th class="cursor" @click="set_sort('one_repair_price')">
            1 ремонт &#8381;
          </th>
          <th class="cursor" @click="set_sort('count_repair_all')">
            всего ремонтов
          </th>
          <th class="cursor" @click="set_sort('summ')">зч и работы &#8381;</th>
          <th class="cursor" @click="set_sort('summ_zap')">запчасти &#8381;</th>
          <th class="cursor" @click="set_sort('summ_price_repair_all')">
            работы &#8381;
          </th>
          <th>зч / работы %</th>
          <th class="cursor" @click="set_sort('count_repair')">ремонт</th>
          <th class="cursor" @click="set_sort('percent_repair')">%</th>
          <th class="cursor" @click="set_sort('summ_price_repair')">&#8381;</th>
          <th class="cursor" @click="set_sort('count_act')">акты</th>
          <th class="cursor" @click="set_sort('percent_act')">%</th>
          <th class="cursor" @click="set_sort('summ_price_act')">&#8381;</th>
          <th class="cursor" @click="set_sort('count_diagnostiks')">
            диагностика
          </th>
          <th class="cursor" @click="set_sort('percent_diagnostiks')">%</th>
          <th class="cursor" @click="set_sort('summ_price_diagnostiks')">
            &#8381;
          </th>
          <th class="cursor" @click="set_sort('count_no_guarantee')">
            не гарантия
          </th>
          <th class="cursor" @click="set_sort('percent_no_guarantee')">%</th>
          <th class="cursor" @click="set_sort('count_predtorg')">
            предторг
          </th>
          <th class="cursor" @click="set_sort('percent_predtorg')">%</th>
        </tr>
        <tbody class="">
          <tr v-if="list_itog">
            <th></th>
            <th></th>
            <th>{{ list_itog.one_repair_price }}</th>
            <th class="text-center">{{ list_itog.count_repair_all }}</th>
            <th>{{ list_itog.summ }}</th>
            <th>{{ list_itog.summ_zap }}</th>
            <th>{{ list_itog.summ_price_repair_all }}</th>
            <th class="text-center">{{ list_itog.percent_zap_and_repairs }}</th>
            <th class="text-center">{{ list_itog.count_repair }}</th>
            <th>{{ list_itog.percent_repair }}</th>
            <th>{{ list_itog.summ_price_repair }}</th>
            <th class="text-center">{{ list_itog.count_act }}</th>
            <th>{{ list_itog.percent_act }}</th>
            <th>{{ list_itog.summ_price_act }}</th>
            <th class="text-center">{{ list_itog.count_diagnostiks }}</th>
            <th>{{ list_itog.percent_diagnostiks }}</th>
            <th>{{ list_itog.summ_price_diagnostiks }}</th>
            <th class="text-center">{{ list_itog.count_no_guarantee }}</th>
            <th>{{ list_itog.percent_no_guarantee }}</th>
            <th class="text-center">{{ list_itog.count_predtorg }}</th>
            <th>{{ list_itog.percent_predtorg }}</th>
          </tr>
          <tr v-for="(item, idx) in fulter_list" :key="idx + 't'">
            <td>{{ idx + 1 }}</td>
            <td :class="order === 'user' && 'sort'">{{ item.user }}</td>
            <td
              :class="order === 'one_repair_price' && 'sort'"
              title="стоимость одного ремонта"
            >
              {{ item.one_repair_price }}
            </td>
            <td
              :class="order === 'count_repair_all' && 'sort'"
              class="text-center"
              title="всего ремонтов в том числе акты и диагностики"
            >
              {{ item.count_repair_all }}
            </td>
            <td
              :class="order === 'summ' && 'sort'"
              title="стоимость запчастей и работ"
            >
              {{ item.summ }}
            </td>
            <td
              :class="order === 'summ_zap' && 'sort'"
              title="стоимость запчастей"
            >
              {{ item.summ_zap }}
            </td>
            <td
              :class="order === 'summ_price_repair_all' && 'sort'"
              title="стоимость работ по ремонтам актам диагностикам"
            >
              {{ item.summ_price_repair_all }}
            </td>
            <td
              :class="order === 'percent_rabot' && 'sort'"
              class="text-center"
              title="соотношение запчастей к работам"
            >
              {{ `${item.percent_zap}/${item.percent_rabot}` }}
            </td>
            <td
              :class="order === 'count_repair' && 'sort'"
              class="text-center"
              title="количество ремонтов"
            >
              {{ item.count_repair }}
            </td>
            <td
              :class="order === 'percent_repair' && 'sort'"
              title="процент ремонтов от общего числа"
            >
              {{ item.percent_repair }}
            </td>
            <td
              :class="order === 'summ_price_repair' && 'sort'"
              title="стоимость работ по ремонтам"
            >
              {{ item.summ_price_repair }}
            </td>
            <td
              :class="order === 'count_act' && 'sort'"
              class="text-center"
              title="количество актов"
            >
              {{ item.count_act }}
            </td>
            <td
              :class="order === 'percent_act' && 'sort'"
              title="процент актов от общего числа ремонтов"
            >
              {{ item.percent_act }}
            </td>
            <td
              :class="order === 'summ_price_act' && 'sort'"
              title="стоимость работ по актам"
            >
              {{ item.summ_price_act }}
            </td>
            <td
              :class="order === 'count_diagnostiks' && 'sort'"
              class="text-center"
              title="количество диагностик"
            >
              {{ item.count_diagnostiks }}
            </td>
            <td
              :class="order === 'percent_diagnostiks' && 'sort'"
              title="процент диагностик от общего числа ремонтов"
            >
              {{ item.percent_diagnostiks }}
            </td>
            <td
              :class="order === 'summ_price_diagnostiks' && 'sort'"
              title="стоимость работ по диагностикам"
            >
              {{ item.summ_price_diagnostiks }}
            </td>
            <td
              :class="order === 'count_no_guarantee' && 'sort'"
              class="text-center"
              title="количество не гарантии"
            >
              {{ item.count_no_guarantee }}
            </td>
            <td
              :class="order === 'percent_no_guarantee' && 'sort'"
              title="процент не гарантии от общего числа ремонтов"
            >
              {{ item.percent_no_guarantee }}
            </td>
            <td
              :class="order === 'count_predtorg' && 'sort'"
              class="text-center"
              title="количество предторга"
            >
              {{ item.count_predtorg }}
            </td>
            <td
              :class="order === 'percent_predtorg' && 'sort'"
              title="процент предторга от общего числа ремонтов"
            >
              {{ item.percent_predtorg }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "StatsSc",
  computed: {
    fulter_list: function() {
      const f_list = this.list;
      return f_list.filter(item =>
        item.user.toLowerCase().includes(this.search_text.toLowerCase())
      );
    }
  },
  data: () => ({
    year: new Date().getFullYear(),
    list: [],
    list_itog: null,
    search_text: "",
    order: "count_repair_all",
    type_order: "DESC",
    count_repair: ""
  }),
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "/stats_users/get_data.php",
        params: {
          year: this.year,
          order: this.order,
          type_order: this.type_order,
          count_repair: this.count_repair
        }
      }).then(response => {
        this.list = response.data.list;
        this.list_itog = response.data.itog;
        console.log(this.list_itog);
      });
    },
    years() {
      const year_start = new Date().getFullYear();
      let arr = [];
      for (let i = year_start; i >= 2021; i--) {
        arr.push(i);
      }
      return arr;
    },
    change_filter() {
      this.get_data();
    },
    set_sort(type) {
      if (this.order === type) {
        if (this.type_order === "ASC") {
          this.type_order = "DESC";
        } else {
          this.type_order = "ASC";
        }
      } else {
        this.type_order = "DESC";
      }
      this.order = type;
      this.get_data();
    }
  }
};
</script>
<style scoped>
th {
  font-size: 0.6em;
  vertical-align: middle;
}
td {
  font-size: 0.7em;
  vertical-align: middle;
}
.sort {
  color: #007bff;
}
.cursor {
  cursor: pointer;
}
table > tbody > tr:hover {
  background: #ffc107;
}
</style>
